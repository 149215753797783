import * as React from "react"
import { useForm } from "@formspree/react";
import CamposHiddenMarketing from "./campos-hidden-marketing";

import PDFDownload from "../assets/Vericode-ISG-Provider-Lens-2022-Continuous-Testing-Specialists-pt.pdf";
import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ContactForm = ({ origin, title, description, showTexts = true, direction = 'row', type = 'default', btnLabel = 'form.button.normal', formType = 'contact' }) => {
  const [state, handleSubmit] = useForm("xeqdoneg");
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window != 'undefined' && window.RDStationForms)
      new window.RDStationForms('contato-site-8531de1d6190cbe71c0c', 'UA-194577423-1').createForm();
      new window.RDStationForms('form-isg-d5cf39546a07320a8792', 'UA-194577423-1').createForm();
  }, []);

  React.useEffect(() => {
    if (state.succeeded && formType === 'download') {
      const link = document.createElement('a');
      link.href = PDFDownload;
      link.download = "Vericode-ISG-Provider-Lens-2022-Continuous-Testing-Specialists-pt.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (state.succeeded && typeof window !== "undefined") {
      window.dataLayer.push({
        'event': 'sendEvent',
        'eventCategory': formType === 'download' ? 'isg: ' : 'cadastro: ',
        'eventAction': 'submit',
        'eventLabel': formType === 'download' ? 'isg' : 'contato',
      });
    }
  }, [state.succeeded])

  if (state.succeeded) {
    return (
      <>
        <h4 className="py-16 mx-auto text-3xl text-center">
          <Trans i18nKey='message.thanks' />
          <small className="block mt-5 text-base">
            <Trans i18nKey={formType === 'download' ? 'message.download.processed' : 'message.sent'} />
          </small>
        </h4>
      </>
    );
  }

  return (
    <div className={`contactForm--direction-${direction}`}>
      {showTexts &&
        <div className="contactForm__text">
          <h2>{title || t('form.title')}</h2>
          <p>{description || t('form.description')}</p>
        </div>
      }

      {
        formType === 'download' ?
          <div  className='contactForm__form' role="main" id="form-isg-d5cf39546a07320a8792" />
          :
          <div className='contactForm__form' role="main" id="contato-site-8531de1d6190cbe71c0c" />
      }

    </div>
  )
}

export default ContactForm
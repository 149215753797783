import * as React from "react"
import { graphql } from 'gatsby'

// components
import Layout from '../components/layout'
import ContactForm from '../components/contact-form'
import { IconFacebook, IconLinkedin, IconTwitter } from '../components/icons';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

const DefaultTemplate = ({ path, data }) => {
  const { language, t } = useI18next();
  const urlToThisPage = "https://vericode.com.br" + path;
  const post = data.markdownRemark;
  const seo = {
    title: post.frontmatter.title
  }
  const postType = post.frontmatter.posttype;
  const category = {
    produto: {
      en: 'Products',
      pt: 'Produtos'
    },
    servico: {
      en: 'Services',
      pt: 'Serviços'
    },
    industria: {
      en: 'Industries',
      pt: 'Indústrias'
    },
    vericode: {
      en: 'Vericode',
      pt: 'Vericode'
    }
  }

  return (
    <>
      <Layout seo={seo}>
        {post.frontmatter.posttype === 'blog'
          ?
          <section className="pageHeader pageHeader--full pageHeader--imageOffset">
            <div className="container">
              <h1>
                <Trans i18nKey='blog.post.title' />
              </h1>
              <div className="xl:w-2/3">
                <h2 className="title title--display title--section">{seo.title}</h2>
                <div className="text-sm text-silver">
                  <Trans i18nKey='blog.post.author' />
                  <strong className="text-white">Team Vericode</strong> em <span>11 de abril de 2013</span>
                </div>
              </div>
            </div>

            <div className="imageOffset mt-lg">
              <div className="image image--rounded image--shadow image--tint image--secondary">
                <img width="100%" className="rounded-lg" alt="" src="https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1920&h=1080&q=80" />
              </div>
            </div>
          </section>
          :
          <section className="pageHeader">
            <div className="container">
              <h1>{category[postType][language]} / <span>{seo.title}</span></h1>
              <h2 className="title title--display">{post.frontmatter.subtitle || seo.title}</h2>
            </div>
          </section>
        }

        <div className="container">
          <div className="mt-lg">
            <article
              className={post.frontmatter.posttype === 'blog' ? 'article article--blog mb-lg' : 'article article--template'}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />

            {post.frontmatter.posttype === 'blog' &&
              <>
                <hr className="divider" />
                <h3 className="text-center mt-lg"><Trans i18nKey='blog.post.share' /></h3>
                <div className="flex justify-center space-x-sm">
                  <a rel="noreferrer" target="_blank" href={`https://www.linkedin.com/cws/share?url={${urlToThisPage}}`}>
                    <span className="hidden">Linkedin</span>
                    <IconLinkedin className="icon" />
                  </a>
                  <a rel="noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?s=100&p[url]=${urlToThisPage}`}>
                    <span className="hidden">Facebook</span>
                    <IconFacebook className="icon" />
                  </a>
                  <a rel="noreferrer" target="_blank" href={`https://twitter.com/intent/tweet?url=${urlToThisPage}`}>
                    <span className="hidden">Twitter</span>
                    <IconTwitter className="icon" />
                  </a>
                </div>
              </>
            }
          </div>
        </div>

        <div className="containerFluid mt-lg md:mt-xl">
          <section className="rounded section section--nopadding section--light">
            <div className='container lg:flex py-lg'>
              <ContactForm origin={path} title={t('form-template.title')} description={t('form-template.description')} />
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($pathSlug: String!, $language: String!) {
    markdownRemark(
      frontmatter: {
        path: {eq: $pathSlug}
        locale: {eq: $language}
      }
    ) {
      id
      html
      frontmatter {
        path
        title
        posttype
        locale
      }
    }

    locales: allLocale(
      filter: { ns: { in: ["default-template", "contact-form", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default DefaultTemplate
